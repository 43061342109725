.media-wrapper {
  .ui-dialog-titlebar {
    background-color: $wellhub-primary !important;
    color: $wellhub-primary-text !important;
    background: none;
  }
  #branding {
    padding: 0px;
    #media-tabs-wrapper {
      float: left;
      .nav-tabs {
        border: none;
      }
    }
  }
}

#mediaBrowser {
  body {
    padding-top: 0;
  }
  #media-browser-tabset {
    background-color: red;
  }
}

.button-yes {
  @extend .btn-secondary;
  @extend .btn;
  color: $wellhub-secondary-text;
}

body.page-media-browser{
  padding-top: 0px !important;
}

#media-browser-tabset #branding {
  padding: 0px;
}

#media-tabs-wrapper{
  float: left;
  .nav-tabs {
    border: none;
  }
}

.ui-tabs .ui-tabs-nav li.ui-tabs-active a{
  background-color: $wellhub-primary;
  color: $wellhub-primary-text;
}

#media-tab-upload {
  font-size: 1rem;

  .form-submit {
    background-color: $wellhub-secondary;
    color: $wellhub-secondary-text;
  }

  .help-block {
    margin-top: 2rem;
    font-size: 0.8rem;
  }
}

#views-exposed-form-media-default-media-browser-1 .views-exposed-widgets {
  padding: 1rem !important;
  font-size: 1rem !important;
  h2 {
    display: none !important;
  }
}

#views-exposed-form-media-default-media-browser-my-files .views-exposed-widgets {
  padding: 1rem !important;
  font-size: 1rem !important;
  h2 {
    display: none !important;
  }
}
.manualcrop-thumblist {
  font-size: 1rem;
  border-left: 2px solid $wellhub-darkgrey;
}

.manualcrop-style-thumb {
  margin-left: 1rem;
}

.manualcrop-style-thumb strong{
  font-size: 0.8rem;
  font-weight: normal;
}

.media-widget .preview {
  float: left;
  margin-right: 1rem;
}
.media-widget .form-type-textfield {
  clear: both;
  margin-top: 2rem !important;
}
