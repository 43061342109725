// Helpers
// -----------------------------------------------------------------------------

.sticky-offset {
  top: 133px !important;
}
.font-size-1 {
  font-size: 1rem;
}
.img-gradient:after {
  content:'';
  position:absolute;
  left:0; top:0;
  width:100%; height:100%;
  display:inline-block;
  background: -moz-linear-gradient(top, rgba(0,47,75,0) 0%, rgba(32, 32, 32, 0.9) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(32, 32, 32, 0)), color-stop(100%,rgba(0,47,75,0.9))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(0,47,75,0) 0%,rgba(32, 32, 32, 0.9) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(0,47,75,0) 0%,rgba(32, 32, 32, 0.9) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(0,47,75,0) 0%,rgba(32, 32, 32, 0.9) 100%); /* IE10+ */
  background: linear-gradient(to bottom, rgba(0,47,75,0) 30%,rgba(32, 32, 32, 0.9) 85%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002f4b', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
}

.img-darken:after {
  content:'';
  position:absolute;
  left:0; top:0;
  width:100%; height:100%;
  display:inline-block;
  background: -moz-linear-gradient(top, rgba(0,47,75,0.65) 0%, rgba(27, 27, 27, 1) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(27, 27, 27, 0)), color-stop(100%,rgba(0,47,75,0.5))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(0,47,75,0.65) 0%,rgba(27, 27, 27, 1) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(0,47,75,0.65) 0%,rgba(27, 27, 27, 1) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(0,47,75,0.65) 0%,rgba(27, 27, 27, 1) 100%); /* IE10+ */
  background: linear-gradient(to bottom, rgba(0,47,75,0.65) 40%,rgba(27, 27, 27, 1) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002f4b', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
}

img {
  max-width: 100%;
  flex-shrink: 0;
}
