// Styles for fields.
// -----------------------------------------------------------------------------
.label-inline {
  display: inline !important;
}

.badge-wellbeing {
  padding: 0.5rem;
  float: right;
  margin: 0 0 1rem 1rem;
  color: $white;
}
.badge-primary {
  color: $wellhub-primary-text;
  background-color: $wellhub-primary;
}
.badge-wellbeing-account {
  padding: 0.5rem;
  float: left;
  margin: 0 1rem 0.25rem 0;
  color: $white;
}
.badge-wellbeing-homepage {
  padding: 0.5rem;
  float: left;
  color: $wellhub-primary-text;
  font-size: 1rem;
  border-radius: 0;
  background: $wellhub-primary-transparent;
}
.badge-viewall {
  font-size: 1rem;
}
.badge-tertiary {
  background-color: $teal;
}
.field-name-field-content-supplied-footer {
  h3 {
    color: $wellhub-primary;
    margin-bottom: 1rem;
  }
  h3.float-left {
    margin: 0.75rem 1rem 0 0;
  }
  li {
    font-size: 40px;
    margin-right: 1rem;
  }
  a {
    color: $wellhub-secondary !important;
  }
}

.card-footer {
  .tag--item {
    color: $white;
    border:1px solid darken($wellhub-lightgrey, 10);
    margin: 0.175rem;
    a {
      padding: 0.25rem;
    }
  }
  .tag--item:nth-child(odd) {
    background: $wellhub-footer-links;
    a {
      color: $wellhub-lightgrey;
    }
  }
  .tag--item:nth-child(even) {
    background: darken($wellhub-footer-links, 10);
    a {
      color: $wellhub-lightgrey;
    }
  }
}

.card {
  p {
    margin: 0;
  }
}

.image--attribution {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}
