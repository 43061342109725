// Styles for blocks.
// -----------------------------------------------------------------------------
.retro-layout-2 .text {
  position: absolute;
  bottom: 0;
  z-index: 10;
  padding: 5px;
  width: 100%;
  &.background--light {
    color: black;
  }

  &.background--dark {
    color: white;
  }

  &.background--complex {
    color: gray;
  }
}

.darken-overlay-text .text {
  position: absolute;
  z-index: 10;
  width: 100%;
  &.background--light {
    color: black;
  }

  &.background--dark {
    color: white;
  }

  &.background--complex {
    color: gray;
  }
}

div:not(.bg-info) > .pane-title {
  color: $primary;
}


.arrow_box {
  position: relative;
  background: $wellhub-midgrey;
  border-bottom: 1px solid $wellhub-footer-links;
}
.arrow_box:after, .arrow_box:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow_box:after {
  border-color: rgba(136, 183, 213, 0);
  border-top-color: $wellhub-footer-links;
  border-width: 10px;
  margin-left: -10px;
  z-index: 2;
}
.arrow_box:before {
  border-color: rgba(194, 225, 245, 0);
  border-top-color: $wellhub-footer-links;
  border-width: 16px;
  margin-left: -16px;
  z-index: 2;
}

.children-wrapper {
  .child:last-child {
    .arrow_box{
      border: 0px solid $wellhub-footer-links;
    }
  }
}

.pane-mwb-event-registration-list,
.pane-mwb-news-registration-list {
  .registree--view {
    background-color: $wellhub-tertiary;
    color: $wellhub-tertiary-text;
    text-align: center;
    margin-bottom: 1.5rem;
    padding: 1.5rem;
    a {
      color: $wellhub-tertiary-text;
    }
  }
  .waiting--view {
    background-color: $wellhub-secondary;
    color: $wellhub-secondary-text;
    text-align: center;
    margin-bottom: 1.5rem;
    padding: 1.5rem;
    a {
      color: $wellhub-tertiary-text;
    }
  }
  table {
    margin-bottom: 0;
  }
}
