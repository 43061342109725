// Typography
// -----------------------------------------------------------------------------
h1, h2, h3, h4, h5, h6 {
  a {
    color: inherit;
  }
}
.text-white a {
  color: inherit;
}
.text-wh-primary-text {
  a {
    color: inherit;
  }
}

.field a {
  color: $wellhub-primary;
}
.field.btn a,
.pager a {
  color: inherit;
}

.field-name-field-helpline {
font-size: 1.2rem;
}
