// Mixins
// -----------------------------------------------------------------------------

@mixin texture($bg-color, $texture-name, $inverted) { $texture-list: "paper.png"             // Fabric and paper
"rice_paper.png"
"let-there-be-sun-pattern.png"
"fabric_plaid.png"
"first_aid_kit.png"
"texturetastic_gray.png"
"lil_fiber.png"
"tex2res5.png"
"skin_side_up.png"
"light_noise_diagonal.png"
"chruch.png"
"45degreee_fabric.png"
"bgnoise_lg.png"
"little_pluses.png"
"squairy_light.png"
"white_texture.png"
"binding_light.png"
"double_lined.png"      // Grid, mesh and squares
"grid.png"
"absurdidad.png"
"grid_noise.png"
"fancy_deboss.png"
"graphy.png"
"noise_pattern_with_crosslines.png"
"old_mathematics.png"
"ps_neutral.png"
"subtle_freckles.png"
"wavegrid.png"
"az_subtle.png"
"tiny_grid.png"
"wavegrid.png"
"gridme.png"
"noisy_grid.png"
"subtlenet2.png"
"white_carbon.png"
"lghtmesh.png"
"p5.png"                 // Dots
"worn_dots.png"
"subtle_dots.png"
"brillant.png"
"farmer.png"
"retina_dust.png"        // Lines
"pinstripe.png"
"line_horizontal1.png"
"line_horizontal2.png"
"linen.png"
"vintage_speckles.png"   // Patina and irregular
"subtle_grunge.png"
"old_wall.png"
"brushed.png"
"stucco.png"
"mooning.png"
"husk.png"
"concrete_wall_2.png"
"noisy.png"
"concrete_wall_3.png"
"subtle_surface.png"
"dust.png"
"light_alu.png"
"corrugation.png"
"kindajean.png"           // Diagonal
"line_diagonal1.png"
"line_diagonal2.png"
"groovepaper.png"
"brushed_alu.png"
"rough_diagonal.png"
"diagonal-noise.png"
"cross_scratches.png"
"striped_lens.png"
"debut_light.png"
"diagonal_waves.png"    // Special
"honey_im_subtle.png"
"pw_maze_white.png"
"subtle_zebra_3d.png"
"white_wave.png"
"circles.png"
"crosses.png"
"grilled.png"
"pw_pattern.png"
"struckaxiom.png"
"vichy.png"
"wavecut.png"
"whitey.png"
"cream_pixels.png"
"grey.png"
"shinedotted.png"
"stacked_circles.png"
"noisy_grid_simple.png"
;

  @if ($inverted == 1) {
    background: url("https://raw.github.com/Magnus-G/texture-mixin/gh-pages/textures/inverted/#{nth($texture-list, $texture-number)}");
    background-color: $bg-color;
  }

  @else {
    background: url("http://www.transparenttextures.com/patterns/#{$texture-name}.png");
    background-color: $bg-color;
  }
}

// Breakpoint mixin.
// Usage: @include breakpoint(sm) { ... }
// -----------------------------------------------------------------------------
@mixin breakpoint($class) {
  @if $class == xs {
    @media (max-width: map-get($container-max-widths, "xs")) { @content; }
  }
  @else if $class == sm {
    @media (min-width: map-get($grid-breakpoints, "sm")) { @content; }
  }
  @else if $class == md {
    @media (min-width: map-get($grid-breakpoints, "md")) { @content; }
  }
  @else if $class == lg {
    @media (min-width: map-get($grid-breakpoints, "lg")) { @content; }
  }
  @else {
    @warn "Breakpoint mixin supports: xs, sm, md, lg";
  }
}
