// Styles for User pages
// -----------------------------------------------------------------------------

.standard--dashboard {
  .pane-dashboard-profile {
    .aside {
      padding: 3rem;
      img {
        margin-bottom: 2rem;
        border-radius: 50%;
      }
    }
  }
}

.community--partner--dashboard {

}

#user-register-form {
  #edit-account,
  #edit-field-firstname #field-firstname-add-more-wrapper,
  #edit-field-surname #field-surname-add-more-wrapper,
  #edit-actions,
  #edit-field-audience {
    width: 70%;
    margin: 0 auto;
    > .form-item {
      width: 50%;
      margin: 0 auto;
      margin-bottom: 2rem;
      label {
        margin-right: 1rem;
      }
    }
    .form-type-radios,
    .form-type-checkbox {
      background-color: $wellhub-lightgrey;
      padding: 1rem;
      margin: 1rem auto;
    }
    .form-type-password-confirm {
      width: 50%;
      margin: 0 auto;
      .form-type-password {
        width: 100%;
        display: block;
      }
    }
  }
  #edit-actions {
    text-align: center;
  }
}
