// Page Layout
// -----------------------------------------------------------------------------
html{
  height: 100%;
}
body {
  background-color: #eeeeee;
  &.panel-layout-sitewide_fullscreen_image {
    background-image: url('../images/user_login_bg.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-color: #999;
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}
body.navbar-administration {
  @media only screen and (min-width : 320px) {
    padding-top: 10px !important;
  }
  @media only screen and (min-width : 640px) {
    padding-top: 156px !important;
  }
}

body:not(.navbar-administration) {
  @media only screen and (min-width : 640px) {
    padding-top: 156px !important;
  }
}

.navbar-search-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background-image: none !important;
  line-height: 1.5rem;
}

#logo img {
  max-height: 78px;
}

.sps--blw {
  //#logo img {
  //  height: 50px;
  //  -moz-transition: width 1s ease-in-out;
  //  -webkit-transition: width 1s ease-in-out;
  //  -moz-transition: width 1s ease-in-out;
  //  -o-transition: width 1s ease-in-out;
  //  transition: width 1s ease-in-out;
  //}

  .header-top {
    padding: 0.25rem !important;
    -moz-transition: padding 1s ease-in-out;
    -webkit-transition: padding 1s ease-in-out;
    -moz-transition: padding 1s ease-in-out;
    -o-transition: padding 1s ease-in-out;
    transition: padding 1s ease-in-out;
    font-size: 1rem;
    a {
      -moz-transition: border 1s ease-in-out;
      -webkit-transition: border 1s ease-in-out;
      -moz-transition: border 1s ease-in-out;
      -o-transition: border 1s ease-in-out;
      transition: border 1s ease-in-out;
      border: none !important;
    }
  }
}


