// Styles for Taxonomy
// -----------------------------------------------------------------------------
.get-healthier-title {
  font-size: 2rem;
  @include media-breakpoint-up(sm) {
    font-size: 2rem;
  }
  @include media-breakpoint-up(md) {
    font-size: 2rem;
  }
  @include media-breakpoint-up(lg) {
    font-size: 2rem;
  }
  @include media-breakpoint-up(xl) {
    font-size: 3rem;
  }
  @include media-breakpoint-up(xxl) {
    font-size: 3rem;
  }
  font-weight: bold;
}
.get-healthier-title--alt {
  font-size: 1.8rem;
  @include media-breakpoint-up(sm) {
    font-size: 1.8rem;
  }
  @include media-breakpoint-up(md) {
    font-size: 1.8rem;
  }
  @include media-breakpoint-up(lg) {
    font-size: 1.8rem;
  }
  @include media-breakpoint-up(xl) {
    font-size: 2.6rem;
  }
  @include media-breakpoint-up(xxl) {
    font-size: 2.6rem;
  }
  font-weight: bold;
}
