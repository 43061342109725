// Styles for Forms
// -----------------------------------------------------------------------------
#mwb-improve-newsletter-signup {
  > div {
    @extend .row;
  }
  .form-item-mail label {
    display: none;
  }
  .form-item-mail {
    @extend .col-md-18;
  }
  .form-submit {
    @extend .col-md-6;
  }
  .form-item-mail {
    margin: 0;
    input {
      margin: 0;
    }
  }
}

#search-block-form,
#mwb-improve-search-box {
  padding: 0.25rem;
  .form-item {
    margin: 0rem;
  }
  .form-submit {
    margin: 0;
    padding: 0rem 0.5rem;
    border: 0;
    font-family: 'FontAwesome';
    color: $wellhub-top-bar-text;
    font-size: 1.2rem;
    width: 100%;
    background-color: $wellhub-top-bar;
    border-radius: 0 .25rem .25rem 0;
  }
  .form-control {
    margin: 0;
    padding: 0.8rem 0.5rem;
    height: calc(1.5rem + 2px);
    border: 1px solid $wellhub-top-bar;
  }
  .form-text {
    border-radius: .25rem 0 0 .25rem;
  }
  .form-actions {
    margin: 0;
  }
}

#mwb-improve-locale-box {
  @include media-breakpoint-down(sm) {
    padding: 0.75rem;
    background-color:$wellhub-primary;
    select {
      width: 100% !important;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      line-height: 1rem;
      position: relative;
      vertical-align: middle;
      zoom: 1;
      background: url(../images/down.png) 96%/5% no-repeat #fff;
      margin-bottom: 1rem;
    }
  }
  .form-item {
    margin: 0rem;
  }
  .form-submit {
    margin: 0;
    padding: 0rem 1.2rem;
    border: 0;
    font-family: 'FontAwesome';
    color: $wellhub-top-bar-text;
    font-size: 1.2rem;
    width: 100%;
    background-color: $wellhub-top-bar;
    border-radius: 0 .25rem .25rem 0;
  }
  .form-control {
    margin: 0;
    padding: 0rem 0.5rem;
    height: calc(1.5rem + 2px);
    border: 1px solid $wellhub-top-bar;
  }
  .form-text {
    border-radius: .25rem 0 0 .25rem;
  }
  .form-actions {
    margin: 0;
  }
  select {
    background: url("../images/down-small.png") 96%/5% no-repeat #fff;
    width: 100%;
  }
}

#edit-field-audience-wrapper {
  display: none;
}

.improve-finder {
  margin-bottom: 0;
  .finder-label {
    font-size: 2.1875rem;
    padding-right: 1rem;
  }
  @media only screen and (max-width : 991px) {
    .finder-label--start {
      width: 100%;
      display: block !important;
      font-size: 2rem;
      margin: 0px;
      padding: 0;
    }
    .finder-label--middle {
      width: 100%;
      display: block !important;
      font-size: 2rem;
      margin: 0px;
      padding: 0;
    }
    #dropdown-second-replace {
      width: 100%;
      display: block !important;
    }
    #edit-submit {
      width: 100%;
      display: block !important;
    }
    select[name="dropdown_first"],
    select[name="dropdown_second"] {
      width: 100% !important;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      line-height: 1rem;
      position:relative;
      vertical-align: middle;
      zoom: 1;
      background: url("../images/down.png") 96% / 5% no-repeat #FFF;
      margin-bottom: 1rem;
      option {
        text-align: center;
        line-height: 2rem;
      }
    }
  }
  .finder-label--middle {
    padding-left: 1rem;
  }
  * {
    display: inline !important;
    width: auto;
  }
  label {
    display: none !important;
  }
  .form-select {
    width: 15% !important;
    font-size: 1.25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    line-height: 1rem;
    position:relative;
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: middle;
    zoom: 1;
    *display: inline;
    background: url("../images/down.png") 96% / 10% no-repeat #FFF;
  }
}

.chosen-drop {
  z-index: 2000;
}

//.form-item-field-get-healthier-category-tid {
//  .form-control {
//    padding: 0;
//  }
//}

.node-form,
#user-profile-form {
  label {
    align-self: flex-start;
    justify-content: left;
  }
  .form-textarea-wrapper textarea {
    width: 100%;
  }
  input[type="checkbox"], input[type="radio"] {
    margin-right: 0.5rem;
  }
  .container-inline-date, .container-inline-date .form-item {
    width: 100%;
  }
  .container-inline-date .form-item input {
    margin-right: 0;
  }
  .help-block {
    padding: 0 7.5px;
    //text-align: right;
    @include make-col(24);
  }
  .password-confirm {
    width: 100%;
    display: block;
  }
  .password-strength {
    width: 100%;
    display: block;
    padding: 1rem;
    margin: 2rem;
    background-color: $secondary;
    color: $wellhub-secondary-text;
  }
  .password-confirm{
    margin-top: 0rem;
    padding: 0.5rem;
  }
  .password-suggestions {
    border: 1px solid $secondary;
    margin: 0.7em 0.5rem;
    width: 100%;
  }
  .form-item-pass-pass1,
  .form-item-pass-pass2 {
    width: 100% !important;
  }
  .link-field-subrow {
    @include make-col(16);
  }
  .field-type-image .help-block {
    padding: 0 7.5px;
    text-align: right;
    @extend .col-md-16;
  }
  legend {
    font-size: 0.875rem;
    font-weight: bold;
    padding: 0 7.5px;
  }
  .media-widget, .file-widget {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .media-widget.ajax-new-content {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .browse,
  .file-widget .btn,
  .image-widget .btn {
    background-color: $primary;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    color: $wellhub-primary-text;
    display: inline-block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.375rem 0.75rem;
    text-align: center;
    vertical-align: middle;
    width: 100%;
  }
  .file-widget .btn,
  .image-widget .btn {
    margin-top: 1rem;
  }
  .browse:hover, .btn:hover {
    background-color: #3ecdfa;
    border-color: #31cafa;
    text-decoration: underline;
  }
  .btn-primary {
    color: $white;
  }
  label.col-sm-8,
  .fieldset-legend,
  table thead th {
    font-size: 1rem;
  }
  #edit-field-address-und-add-more {
    @extend .btn;
    @extend .btn-secondary;
    color: $wellhub-secondary-text;
  }
  .form-item-field-promo-image-und-0 {
    label {
      visibility: hidden;
    }
  }
  .fieldset-legend {
    @extend .col-sm-8;
  }
  input[type="text"]:focus,
  input[type="password"]:focus{
    border-bottom: 2px solid $secondary;
  }
  .link-field-subrow {
    .form-group {
      margin-top: 0;
      width: 94%;
      input {
        margin: 0;
        @extend .col-24;
      }
    }
  }
  .image-widget {
    @extend .col-16;
  }
  div.image-widget-data {
    float: none;
    .manualcrop-style-thumb {
      .manualcrop-preview {
        float: left;
      }
      strong{
        float: right;
        color: $secondary;
      }
    }
  }
  .manualcrop-button {
    @extend .btn;
    @extend .btn-secondary;
  }
  .form-inline {
    label {
      @extend .col-8;
    }
  }

  .date-combo {
    background-color: rgba(0,0,0,0.05);
  }

  .form-type-password-confirm {
    background-color: #ededed;
    padding: 2rem;
  }

}

//.text-format-wrapper {
//  .form-type-textarea {
//    display: block;
//  }
//}

.text-format-wrapper {
  .form-type-textarea {
    display: block;
  }
}

.aside {
  form {
    .form-block {
      input, textarea {
        width: 100%;
      }
    }
  }
}

.bliss--user-login--form-wrapper {
  .aside {
    .content {
      padding: 3rem;
      background: rgba(255, 255, 255, 0.8);
      border-radius: 1.25rem;
    }
  }
  .text-muted {
    color: $wellhub-darkgrey !important;
  }
  input {
    width: 100% !important;
  }
  #edit-submit {
    width: 100%;
    padding: 1rem 2rem;
    font-size: 1.25rem;
    margin-top: 1rem;
    background-color: $wellhub-brand-blue;
    color: $white;
    border-radius: 1rem;
    border: 0;
  }
}

.manualcrop-init-processed {
  .help-block {
    display: none;
  }
  input {
    width: 100% !important;
  }
  .remove {
    @extend .btn-danger;
  }
}
.field-widget-media-generic {
  .remove {
    @extend .btn-danger;
  }
  .ctools-modal-media-file-edit.button {
    @extend .btn-secondary;
    @extend .btn;
    margin-bottom: 1rem;
  }
}
#edit-field-file {
  input.form-submit {
    @extend .btn-primary;
    @extend .btn;
  }
}

@include media-breakpoint-up(lg) {
  #collapseFilter {
    display: block !important;
  }
}
@include media-breakpoint-down(lg) {
  .filter-content-title {
    width: 100%;
    background-color: $wellhub-secondary;
    color: $wellhub-secondary-text;
    padding: 1rem;
  }
}

select {
  width: 100% !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  line-height: 1rem;
  position:relative;
  vertical-align: middle;
  zoom: 1;
  background: url("../images/down.png") 96% / 5% no-repeat #FFF;
  background-size: 22px 22px;
}

.pane-views-exposed {
  form {
    .active::after {
      @extend .fa;
      content: "\f00c";
      padding-left: 1rem;
    }
  }
}

.form-media {
  .form-item {
    white-space: normal !important;
  }
  .help-block {
    display: none;
  }
}

a.tabledrag-handle .handle {
  height: 24px;
  width: 24px;
}

.field-name-field-file {
  .fieldset-wrapper {
    .col {
      label.col-sm-8{
        display: none;
      }
    }
  }
}

.bef-select-as-links {
  .form-inline {
    display: block;
    .form-group {
      display: block;
      float: left;
    }
  }
}
.select-all {
  width: 13%;
}
.select-all [type=checkbox]:after {
  content: "Select All/None";
  margin: -3px 15px;
  vertical-align: top;
  display: inline-block;
  white-space:nowrap;
  cursor:pointer;
}
