// Styles for Tabs, Messages, ..etc
// -----------------------------------------------------------------------------

.header-top {
  color: $wellhub-primary-text;
  .header-top--left {
    a {
      background-color: transparent;
      color: $wellhub-primary-text;
    }
  }
  .header-top--right {
    a {
      //background-color: $wellhub-top-bar;
      color: $wellhub-primary-text;
    }
    .top-menu-logo {
      max-height: 20px;
      width: auto;
    }
  }
}

.mobile--header {
  .pane-top-menu {
    ul {
      border-top: $wellhub-secondary;
      background-color: $wellhub-primary;
      color: $wellhub-primary-text;
      a {
        color: $wellhub-primary-text;
      }
      //padding: 0;
      //margin: 0;
      //div {
      //  padding: 0 !important;
      //  margin: 0 !important;
      //}
    }
  }
}
.wellhub-logo {
  @include media-breakpoint-down(sm) {
    width: 75%;
    margin: 0 auto;
  }
}

#main {
  background-color: #eeeeee;
  clear: both;
}

#footer {
  color: $wellhub-lightgrey;
  a {
    color: $wellhub-footer-links;
    &:hover {
      color: $wellhub-lightgrey;
    }
  }
  .footer--bottom {
    color: $wellhub-footer-links;
  }
}

.texture-1 {
  @include texture($wellhub-primary-alt, '45-degree-fabric-light', 0);
}

.navbar-administration.navbar-tray-open {
  .header {
    margin-top: 78px;
  }
  #main {
    margin-top: 78px;
  }
}

.navbar-administration {
  .header {
    margin-top: 38px;
  }
  #main {
    margin-top: 38px;
  }
}

.shepherd-header {
  margin: 0;
}

.height540 {
  @include media-breakpoint-down(sm) {
    height: 262px;
  }
  @include media-breakpoint-up(sm) {
    height: 540px;
  }
}
.height263 {
  @include media-breakpoint-up(sm) {
    height: 262px;
  }
  @include media-breakpoint-down(sm) {
    height: 262px;
  }
}
