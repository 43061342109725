// Styles for Menus and Navs
// -----------------------------------------------------------------------------
#navbar-administration{
  z-index: 1031;
  #navbar-bar {
    z-index: 1531;
  }
}
#header {
  @include breakpoint(lg) {
    ul.navbar-nav {
      li {
        a {
          padding: 1rem;
          color: $wellhub-primary;
          font-size: 1.2rem;
          margin-left: 1rem;
        }
        &.last a{
          padding-right: 0px;
        }
      }
    }
  }
}

.pane-local-tasks-blocks-menu-menu-local-tasks {
  ul.menu {
    flex-direction: column!important;
    li {
      a {
        display: block;
        color: $white;
        &.active-trail{
          color: $light !important;
          font-weight: bold;
        }
      }
    }
  }
}

.active-trail {
  color: $wellhub-grey !important;
}

.nw-c-slice-heading:before {
  content: '';
  position: absolute;
  height: 2px;
  background: $wellhub-primary;
  left: 16px;
  right: 10px;
  top: 18px;
}

@media only screen and (max-width : 991px) {
  #navbarSupportedContent {
    font-size: 16px;
    background: $wellhub-darkgrey;
    padding: 5px;
    ul {
      li {
        a {
          display: block;
          width: 100%;
          clear: both;
          border-radius: 4px;
          padding: 0.5em;
          border-bottom: 1px solid #000;
          color: $white;
          text-decoration: none;
          text-align: center;
        }
      }
      li:last-child {
        a {
          border-bottom: 0px solid #000;
        }
      }
    }
  }
}
