// Styles for panels and panes.
// -----------------------------------------------------------------------------
.style--primary {
  color: $wellhub-tertiary-text;
  h1,h2,h3,h4,h5,.pane-title {
    color: $wellhub-tertiary-text !important;
  }
  .btn {
    background-color: $tertiary;
    color: $wellhub-tertiary-text;
  }
}

.card-container {
  position: relative;
}

.card-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
}

.card-hover-activator:hover .card-overlay {
  opacity: 1;
}
